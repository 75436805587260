import {
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import RoundBackgroundInput from '../input/RoundBackgroundInput';
import { images } from '../../assets/images';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { appEnvironment, autoCompleteData } from '../../common/environment';
import validator from '../../onboarding/common/validator';
import { checkoutActions } from '../../redux/checkout';
import { useAppDispatch } from '../../redux/store';
import PasswordStrengthView from '../input/PasswordStrengthView';
import { onboardingActions } from '../../redux/onboarding';
import { ContinueRef } from '../../onboarding/types';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { addDiscountCodeV2 } from '../../redux/checkoutActions';
import { useEmailAvailableMutation } from '../../api/api';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { useMobile } from '../../common/hooks';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import i18n from '../../i18n';

const SignUpForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
        screen: analyticsScreenNames.CREATE_ACCOUNT,
      });

      if (!hasErrors) {
        const { available } = await checkEmailAvailability({
          email: email.toLowerCase(),
        }).unwrap();
        if (!available) {
          setEmailError(t('emailIsAlreadyTaken'));
          return;
        }
        try {
          Sentry.setUser({
            email: email.toLowerCase(),
            name,
            lastName,
          });

          await dispatch(
            addDiscountCodeV2(
              coupon.length > 0 ? coupon : appEnvironment.defaultCoupon
            )
          ).unwrap();
          dispatch(
            checkoutActions.setPersonalData({
              name,
              lastName,
              email: email.toLowerCase(),
              password,
              code: coupon,
            })
          );
          dispatch(
            onboardingActions.setOnboardingStep(OnboardingV2Step.SECOND_FORM)
          );
        } catch (e) {
          setCouponError(t('invalidCoupon'));
        }
      }
      return {};
    },
  }));

  const language = i18n.language;

  const dispatch = useAppDispatch();

  const [checkEmailAvailability] = useEmailAvailableMutation();

  const [tcsAgreed, setTcsAgreed] = useState(
    appEnvironment.autocomplete ? true : false
  );

  const [passwordOn, setPasswordOn] = useState(true);

  const [name, setName] = useState(
    appEnvironment.autocomplete ? autoCompleteData.name : ''
  );
  const [lastName, setLastName] = useState(
    appEnvironment.autocomplete ? autoCompleteData.lastName : ''
  );
  const [coupon, setCoupon] = useState(
    appEnvironment.autocomplete ? autoCompleteData.coupon : ''
  );
  const [email, setEmail] = useState(
    appEnvironment.autocomplete ? autoCompleteData.email : ''
  );
  const [password, setPassword] = useState(
    appEnvironment.autocomplete ? autoCompleteData.password : ''
  );

  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [couponError, setCouponError] = useState('');
  const [emailError, setEmailError] = useState('');

  const hasErrors = useMemo(() => {
    return (
      name.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      !validator.validEmail(email) ||
      password.length === 0 ||
      !validator.passwordLength(password) ||
      !validator.passwordHasSpecialCharacters(password) ||
      !validator.passwordHasUppercaseAndLowercase(password) ||
      !validator.passwordHasDigit(password) ||
      emailError.length > 0 ||
      !tcsAgreed
    );
  }, [
    email,
    emailError.length,
    lastName.length,
    name.length,
    password,
    tcsAgreed,
  ]);

  useEffect(() => {
    dispatch(onboardingActions.setButtonEnabled(!hasErrors));
  }, [email, password, name, lastName, coupon, hasErrors, dispatch]);

  const mobile = useMobile();

  return (
    <Fade in={true}>
      <Grid
        container
        className={mobile ? 'px-[16px]' : 'w-full'}
        rowGap={2}
        alignContent={mobile ? 'center' : undefined}
        sx={mobile ? { flex: 1, marginTop: '100px' } : {}}
      >
        <Grid item container xs={12} direction="row" justifyContent={'center'}>
          <Grid item md={6} xs={12} alignSelf={'center'}>
            <RoundBackgroundInput
              placeholder={t('accessCode')}
              onChange={(e) => {
                setCouponError('');
                setCoupon(e.target.value);
              }}
              helperText={couponError}
              error={couponError.length > 0}
              value={coupon}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction={i18n.language === 'ja' ? 'row-reverse' : undefined}
        >
          <Grid item xs={true}>
            <RoundBackgroundInput
              placeholder={t('firstName')}
              onChange={(e) => {
                setNameError('');
                setName(e.target.value);
              }}
              onBlur={() => {
                if (name.length === 0) {
                  setNameError(t('pleaseCompleteThisField'));
                }
              }}
              error={nameError.length > 0}
              helperText={nameError}
              value={name}
            />
          </Grid>

          <Box
            component="img"
            src={images.form.nameInput.focused}
            className="mx-4"
          />

          <Grid item xs={true}>
            <RoundBackgroundInput
              placeholder={t('lastName')}
              onChange={(e) => {
                setLastNameError('');
                setLastName(e.target.value);
              }}
              onBlur={() => {
                if (lastName.length === 0) {
                  setLastNameError(t('pleaseCompleteThisField'));
                }
              }}
              helperText={lastNameError}
              error={lastNameError.length > 0}
              value={lastName}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} direction="row" justifyContent={'center'}>
          <RoundBackgroundInput
            onChange={(e) => {
              setEmailError('');
              setEmail(e.target.value);
            }}
            onBlur={() => {
              if (!validator.validEmail(email)) {
                setEmailError(t('invalidEmail'));
              }
            }}
            error={emailError.length > 0}
            helperText={emailError}
            placeholder={t('email')}
            InputProps={
              language !== 'ja'
                ? {
                    endAdornment: (
                      <Box component="img" src={images.form.email.focused} />
                    ),
                    inputMode: 'email',
                  }
                : undefined
            }
            value={email}
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction="column"
          justifyContent={'center'}
        >
          <RoundBackgroundInput
            placeholder={t('password')}
            type={passwordOn ? 'password' : undefined}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <Box
                  component="img"
                  src={
                    passwordOn
                      ? images.v2.inputs.passwordOn
                      : images.v2.inputs.passwordOff
                  }
                  className="hover:cursor-pointer"
                  onClick={() => setPasswordOn((prev) => !prev)}
                />
              ),
            }}
            value={password}
          />

          <PasswordStrengthView password={password} />
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction="column"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <FormControlLabel
            label={
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {language !== 'ja' && (
                  <Typography variant="body1">
                    {t('iAgreeToAll')}&nbsp;
                  </Typography>
                )}

                <a
                  target="_"
                  href="https://syd.life/terms/user_terms.html"
                  className="underline"
                >
                  {language === 'ja'
                    ? t('iAgreeToAllTermsAndConditions')
                    : t('termsAndConditions')}
                </a>
              </Box>
            }
            control={
              <Checkbox
                checked={tcsAgreed}
                onChange={(_event, checked) => {
                  setTcsAgreed(checked);
                  analyticsTracker.track(analyticsEvents.TICK_TC_BOX, {
                    screen: analyticsScreenNames.CREATE_ACCOUNT,
                    checked,
                  });
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Fade>
  );
});

export default SignUpForm;
