import { Fade, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../redux/store';
import { OnboardingV2Step } from '../redux/onboardingTypes';
import { useTranslation } from 'react-i18next';

export default function SYDLeftPanel() {
  const onboardingStep = useAppSelector(
    (state) => state.onboarding.onboardingStep
  );

  const { t } = useTranslation();

  return (
    <>
      {onboardingStep === OnboardingV2Step.ANIMATING && (
        <Fade in={true}>
          <Stack
            justifyContent="center"
            alignItems={'center'}
            gap="10px"
            className="w-[200px] mt-[80px] ml-[-60px]"
          >
            <Typography className="font-semibold text-[24px] text-[#292D3233] whitespace-pre">
              {t('sydCaresAboutYourPrivacy')}
            </Typography>
          </Stack>
        </Fade>
      )}
    </>
  );
}
