import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, GlobalStyles, Stack } from '@mui/material';
import { theme } from '../common/theme';
import OnboardingSignUpView from './OnboardingSignUpView';
import { useLoggedOutHeader } from '../headerHooks';
import HeaderProgressBarMobile from '../components_v2/header/HeaderPogressMobile';
import { useTranslation } from 'react-i18next';

export default function LoggedOutV2Mobile() {
  const { show, step, subtext, white } = useLoggedOutHeader();

  const { t } = useTranslation();

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: {
              background: '#F5F6F6',
              padding: 0,
              margin: 0,
            },
          }}
        />

        <Stack className="root-container">
          {show && (
            <HeaderProgressBarMobile
              label={t('signUp')}
              step={step}
              totalSteps={2}
              subtext={subtext}
              white={white}
            />
          )}

          <OnboardingSignUpView />
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
