import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { images } from '../assets/images';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { onboardingActions } from '../redux/onboarding';
import { OnboardingV2Step } from '../redux/onboardingTypes';
import { Box, Fade, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddressV2 from '../components_v2/forms/AddressvV2';
import { ContinueRef } from '../onboarding/types';
import StripeForm from '../components_v2/forms/StripeForm';
import SignUpForm from '../components_v2/forms/SignUpForm';
import { useSearchParams } from 'react-router-dom';
import CheckoutForm from '../components_v2/forms/CheckoutForm';
import { useTranslation } from 'react-i18next';
import SecondForm from '../components_v2/forms/SecondForm';
import SummaryV2Form from '../components_v2/forms/SummaryV2Form';
import { wait } from '../common/v2/hooks';

const TIMEOUT = 700;

export default function OnboardingSignUpView() {
  const step = useAppSelector((state) => state.onboarding.onboardingStep);

  const stepRef = useRef(step);

  const dispatch = useAppDispatch();

  const hasRun = useRef(false);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [animatingIndex, setAnimatingIndex] = useState(0);

  useEffect(() => {
    const animate = async () => {
      let index = 0;
      while (step === OnboardingV2Step.ANIMATING) {
        index = (index + 1) % 4;

        setAnimatingIndex(index);
        await wait(TIMEOUT);
      }
    };

    if (!hasRun.current) {
      hasRun.current = true;
      animate();

      setTimeout(() => {
        const rtl =
          document.getElementById('root')?.getAttribute('dir') === 'rtl';

        setImageLeft(rtl ? window.innerWidth - 195 : -105);
        setImageTop(-95);
        setScale(0.5);

        dispatch(
          onboardingActions.setOnboardingStep(OnboardingV2Step.MOBILE_SIGN_UP)
        );
      }, 5000);
    }
  }, [dispatch, step]);

  const [imageLeft, setImageLeft] = useState(window.innerWidth / 2 - 310 / 2);
  const [imageTop, setImageTop] = useState(window.innerHeight / 2 - 267 / 2);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (stepRef.current !== step && step === OnboardingV2Step.MOBILE_BUTTON) {
      stepRef.current = step;
    }
  }, [step]);

  const ref = useRef<ContinueRef>(null);
  const loading = useAppSelector((state) => state.checkout.loading);
  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );

  const button = useCallback(
    (black = false, summary = false) => {
      return (
        <LoadingButton
          variant="contained"
          endIcon={
            <Box
              component="img"
              src={black ? images.v2.button.tick : images.v2.button.tickBlack}
            />
          }
          className={`px-[24px] my-[20px] button bg-text`}
          sx={
            black
              ? {}
              : {
                  '&.MuiLoadingButton-root': {
                    background: 'white',
                    color: '#292D32',
                  },
                  '&.Mui-disabled': { background: '#FFFFFF99' },
                }
          }
          loading={loading}
          disabled={!buttonEnabled}
          onClick={() => {
            if (ref.current) {
              ref.current.continuePressed();
            }
          }}
        >
          {summary ? t('downloadTheApp') : t('continue')}
        </LoadingButton>
      );
    },
    [buttonEnabled, loading, t]
  );

  const fullView = useMemo(() => {
    switch (step) {
      case OnboardingV2Step.SUMMARY:
        return (
          <Stack alignSelf={'stretch'} flex={1} sx={{ paddingTop: '120px' }}>
            <SummaryV2Form ref={ref} />

            {button(true, true)}
          </Stack>
        );
      case OnboardingV2Step.SECOND_FORM:
        return (
          <Stack alignSelf={'stretch'} flex={1}>
            <SecondForm ref={ref} />

            {button(true)}
          </Stack>
        );
      case OnboardingV2Step.MOBILE_SIGN_UP:
        return (
          <Stack alignSelf={'stretch'} flex={1}>
            <SignUpForm ref={ref} />

            {button(true)}
          </Stack>
        );
      case OnboardingV2Step.DELIVERY_ADDRESS:
        return (
          <Stack alignSelf={'stretch'} flex={1}>
            <AddressV2 ref={ref} type="delivery" />

            {button(true)}
          </Stack>
        );
      case OnboardingV2Step.PAYMENT_INFO:
        return (
          <Stack alignSelf={'stretch'} className="mx-[16px]">
            <StripeForm ref={ref} />

            {button(true)}
          </Stack>
        );
    }
  }, [button, step]);

  if (searchParams.get('payment_intent') !== null) {
    return <CheckoutForm />;
  }

  return (
    <Stack className={`flex-1 self-stretch bg-[#F5F6F6]`}>
      <Box
        className={`w-[310px] h-[267px] absolute z-10`}
        sx={{
          left: imageLeft,
          top: imageTop,
          transform: `scale(${scale})`,
          transition: 'all 0.5s ease',
        }}
      >
        <Box
          component={'img'}
          className="absolute left-0 right-0 top-0 bottom-0"
          src={images.v2.leftPanel.mobile.sydLeftFaceBackgroundMobile}
        />

        <Fade in={step !== OnboardingV2Step.ANIMATING} timeout={TIMEOUT}>
          <Box
            component={'img'}
            className="absolute left-[58px] top-[110px] w-[110px] h-[110px]"
            src={images.v2.leftPanel.mobile.sydLeftFaceMobile}
          />
        </Fade>

        <Fade
          in={step === OnboardingV2Step.ANIMATING && animatingIndex === 0}
          timeout={TIMEOUT}
        >
          <Box
            component="img"
            src={images.v2.leftPanel.hipaa}
            className="absolute left-[58px] top-[110px] w-[110px] h-[110px]"
          />
        </Fade>

        <Fade
          in={step === OnboardingV2Step.ANIMATING && animatingIndex === 1}
          timeout={TIMEOUT}
        >
          <Box
            component="img"
            src={images.v2.leftPanel.iso}
            className="absolute left-[58px] top-[110px] w-[110px] h-[110px]"
          />
        </Fade>

        <Fade
          in={step === OnboardingV2Step.ANIMATING && animatingIndex === 2}
          timeout={TIMEOUT}
        >
          <Box
            component="img"
            src={images.v2.leftPanel.orcha}
            className="absolute left-[58px] top-[110px] w-[110px] h-[110px]"
          />
        </Fade>

        <Fade
          in={step === OnboardingV2Step.ANIMATING && animatingIndex === 3}
          timeout={TIMEOUT}
        >
          <Box
            component="img"
            src={images.v2.leftPanel.soc2}
            className="absolute left-[58px] top-[110px] w-[110px] h-[110px]"
          />
        </Fade>
      </Box>

      <Box className="h-[60] self-stretch" />

      <Fade in={true} timeout={1000}>
        <Stack
          flex={1}
          justifyContent={
            step === OnboardingV2Step.MOBILE_SIGN_UP ? 'flex-start' : 'center'
          }
          alignItems={'center'}
          sx={{ overflow: 'scroll' }}
        >
          {fullView}
        </Stack>
      </Fade>

      {step === OnboardingV2Step.ANIMATING && (
        <Typography className="font-medium text-[14px] text-[#26262680] self-center absolute bottom-[20px] left-0 right-0 text-center">
          {t('establishingPrivateAndSecureConnection')}
        </Typography>
      )}
    </Stack>
  );
}
