import { Box, Fade, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode, { useQRLink } from '../../common/QRCode';
import { formStyles } from '../../onboarding/styles';
import { images } from '../../assets/images';
import { useMobile } from '../../common/hooks';

export default function QRForm() {
  const { t } = useTranslation();

  const mobile = useMobile();
  const link = useQRLink();

  return (
    <Fade in={true}>
      <Box sx={formStyles.container}>
        {!mobile && (
          <Typography className="large-label" component={'span'}>
            <Typography component={'span'} className="large-label gray">
              {t('next')}
            </Typography>
            {t('downloadTheApp')}
          </Typography>
        )}

        <Stack
          justifyContent={'center'}
          alignItems="flex-start"
          direction={mobile ? 'column' : 'row'}
          gap={'20px'}
        >
          <Stack
            className="rounded-[36px] border-[#0ABAB5] border-[4px] p-[20px] relative max-w-[350px] bg-white40"
            gap={'10px'}
          >
            <Box
              component="img"
              src={images.v2.onboarding.new.check}
              className="absolute right-[-7px] top-[-10px] w-[32px] h-[32px]"
            />

            <Box className="flex justify-center items-center bg-white w-[64px] h-[64px] rounded-[32px]">
              <Typography className="number">1</Typography>
            </Box>

            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              <Box component={'img'} src={images.v2.onboarding.new.tick} />
              <Typography className="title completed">{t('signUp')}</Typography>
            </Stack>

            <Typography className="footer-note">
              {t('yourSydAccountSucessfullyCreated')}
            </Typography>
          </Stack>

          <Stack
            className="rounded-[36px] bg-[#0ABAB5] p-[20px] relative max-w-[350px]"
            gap={'10px'}
            onClick={() => {
              if (mobile) {
                window.location.href = link;
              }
            }}
          >
            <Box className="flex justify-center items-center bg-white w-[64px] h-[64px] rounded-[32px]">
              <Typography className="number">2</Typography>
            </Box>

            <Stack direction={'row'} alignItems={'center'}>
              <Typography className="title white">
                {mobile ? t('tapToDownloadTheApp') : t('downloadTheApp')}
              </Typography>
            </Stack>

            <Stack
              className={`rounded-[120px] ${
                mobile ? 'my-small' : 'bg-white w-[240px] h-[240px]'
              }`}
              justifyContent={'center'}
              alignItems={'center'}
              alignSelf={'center'}
            >
              {mobile ? (
                <Box
                  component={'img'}
                  src={images.v2.onboarding.new.tapToDownloadLogo}
                />
              ) : (
                <QRCode size={150} />
              )}
            </Stack>

            <Typography className="footer-note white">
              {mobile ? t('tapTheLogo') : t('scanTheQRCode')}
            </Typography>

            <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
              <Box component={'img'} src={images.v2.onboarding.new.apple} />
              <Box component={'img'} src={images.v2.onboarding.new.google} />
            </Stack>
          </Stack>

          <Stack
            className="rounded-[36px] border-transparent border-[4px] p-[20px] relative max-w-[350px] bg-white"
            gap={'10px'}
          >
            <Box className="flex justify-center items-center bg-[#F5F6F6] w-[64px] h-[64px] rounded-[32px]">
              <Typography className="number">3</Typography>
            </Box>

            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              <Box component={'img'} src={images.v2.onboarding.new.syd} />
              <Typography className="title dark">{t('meetSyd')}</Typography>
            </Stack>

            <Typography className="footer-note">{t('loginTheApp')}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Fade>
  );
}
