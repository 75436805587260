import sydLogo from './syd_logo.svg';
import iconClose from './icon_close.svg';

import nameInputUnfocused from './input_name_unfocused.svg';
import accessCodeUnfocused from './access_code_unfocused.svg';
import emailUnfocused from './email_unfocused.svg';
import passwordUnfocused from './password_unfocused.svg';

import nameInputFocused from './input_name_focused.svg';
import accessCodeFocused from './access_code_focused.svg';
import emailFocused from './email_focused.svg';
import passwordFocused from './password_focused.svg';

import nameInputFilled from './input_name_filled.svg';
import accessCodeFilled from './access_code_filled.svg';
import emailFilled from './email_filled.svg';
import passwordFilled from './password_filled.svg';

import clear from './clear.svg';
import country from './country.svg';

import verify from './verify.svg';
import sendCode from './send_code.svg';

import male from './male.svg';
import female from './female.svg';
import nonBinary from './non_binary.png';

import checkSelected from './check_selected.svg';
import countryArrowDown from './country_down.svg';
import avatarBorder from './avatar_border.svg';

import skinToneDark from './skin_tone_dark.svg';
import skinToneLight from './skin_tone_light.svg';

import cardNameUnfocused from './card_name_unfocused.svg';
import cvcUnfocused from './card_cvc_unfocused.svg';
import cardExpiryDateUnfocused from './card_expiry_date_unfocused.svg';
import cardNumberUnfocused from './card_number_unfocused.svg';

import cardNameFocused from './card_name_focused.svg';
import cvcFocused from './card_cvc_focused.svg';
import cardExpiryDateFocused from './card_expiry_date_focused.svg';
import cardNumberFocused from './card_number_focused.svg';

import cardNameFilled from './card_name_filled.svg';
import cvcFilled from './card_cvc_filled.svg';
import cardExpiryDateFilled from './card_expiry_date_filled.svg';
import cardNumberFilled from './card_number_filled.svg';

import poweredByStripe from './powered_by_stripe.svg';

import summaryIcons from './summary_icons.svg';
import summarySydLogo from './summary_syd_logo.svg';
import summaryBackground from './summary_background.svg';
import summaryBackgroundMobile from './summary_background_mobile.svg';

import arrowRight from './arrow_right.svg';
import arrowRightWhite from './arrow_right_white.svg';
import arrowLeft from './arrow_left.svg';
import arrowLeftWhite from './arrow_left_white_2.svg';
import line from './line.svg';
import lineWhite from './line_white.svg';
import bullet from './bullet.svg';
import bulletWhite from './bullet_white.svg';

import buttonArrowRight from './button_arrow_right.svg';
import buttonArrowRightDark from './button_arrow_right_dark.svg';

import qr from './qr.jpeg';

import back from './navigation/back.svg';

import sydLeftPanelBackground from './v2/left_panel/syd_left_panel_background.png';
import sydLeftPanelFace from './v2/left_panel/syd_left_face.png';
import sydLeftPanelFaceDesktop from './v2/left_panel/syd_left_face_desktop.png';

import sydLeftFaceBackgroundMobile from './v2/left_panel/mobile/syd_left_face_background.png';
import sydLeftFaceMobile from './v2/left_panel/mobile/syd_left_face_mobile.png';

import hipaa from './v2/left_panel/HIPAA.png';
import iso from './v2/left_panel/ISO.png';
import orcha from './v2/left_panel/ORCHA.png';
import soc2 from './v2/left_panel/SOC2.png';
import gdpr from './v2/left_panel/GDPR.png';

import buttonTick from './v2/button/button_tick.svg';
import buttonTickBlack from './v2/button/button_tick_black.svg';

import passwordOn from './v2/inputs/password_on.svg';
import passwordOff from './v2/inputs/password_off.svg';
import gridItemCheck from './v2/onboarding/grid_item_check.svg';
import gridSelectionRemove from './v2/onboarding/grid_selection_remove.svg';
import lqiDirectionUp from './v2/onboarding/lqi_direction_up.svg';
import lqiHeader from './v2/onboarding/lqi_header.png';
import lqiHeaderVideoMobile from './v2/onboarding/lqi_header_mobile.mp4';
import lqiVideo from './v2/onboarding/lqi_view.mp4';
import lqiVideoDesktop from './v2/onboarding/lqi_view_desktop.mp4';
import completeLogo from './v2/onboarding/complete_logo.png';

import tooltipTriangle from './v2/onboarding/tooltip_triangle.svg';

import securityLogos from './v2/onboarding/mobile/security_logos.png';
import ratingLogos from './v2/onboarding/mobile/rating_logos.png';
import checkInBottomGradient from './v2/onboarding/mobile/check_in_bottom_gradient.png';
import finishedLogo from './v2/onboarding/mobile/finished_logo.png';

import passwordTick from './v2/password/password_check.svg';
import tick from './v2/onboarding/new/tick.svg';
import check from './v2/onboarding/new/check.png';
import google from './v2/onboarding/new/googleplay.png';
import apple from './v2/onboarding/new/appstore.png';
import syd from './v2/onboarding/new/syd.png';
import tapToDownloadLogo from './v2/onboarding/new/tap_to_download_logo.png';

export const images = {
  navigation: {
    back,
  },

  header: {
    sydLogo,
    iconClose,
  },

  form: {
    nameInput: {
      unfocused: nameInputUnfocused,
      focused: nameInputFocused,
      filled: nameInputFilled,
    },
    accessCode: {
      unfocused: accessCodeUnfocused,
      focused: accessCodeFocused,
      filled: accessCodeFilled,
    },
    email: {
      unfocused: emailUnfocused,
      focused: emailFocused,
      filled: emailFilled,
    },
    password: {
      unfocused: passwordUnfocused,
      focused: passwordFocused,
      filled: passwordFilled,
    },
    clear,
    country,
  },

  button: {
    arrowRight: buttonArrowRight,
    arrowRightDark: buttonArrowRightDark,
    verify,
    sendCode,
  },

  genders: {
    male,
    female,
    nonBinary,
  },

  checkSelected,
  countryArrowDown,
  avatarBorder,

  avatars: {
    skinToneDark,
    skinToneLight,
  },

  pagination: {
    arrowRight,
    arrowLeft,

    arrowLeftWhite,
    arrowRightWhite,

    bullet,
    line,

    lineWhite,
    bulletWhite,
  },

  cardForm: {
    cardName: {
      focused: cardNameFocused,
      unfocused: cardNameUnfocused,
      filled: cardNameFilled,
    },
    cvc: {
      focused: cvcFocused,
      unfocused: cvcUnfocused,
      filled: cvcFilled,
    },
    cardExpiryDate: {
      focused: cardExpiryDateFocused,
      unfocused: cardExpiryDateUnfocused,
      filled: cardExpiryDateFilled,
    },
    cardNumber: {
      focused: cardNumberFocused,
      unfocused: cardNumberUnfocused,
      filled: cardNumberFilled,
    },
    poweredByStripe,
  },

  summary: {
    summaryBackgroundMobile,
    summaryBackground,
    summaryIcons,
    summarySydLogo,
  },

  qr,

  v2: {
    leftPanel: {
      sydLeftPanelBackground,
      sydLeftPanelFace,
      sydLeftPanelFaceDesktop,

      hipaa,
      iso,
      orcha,
      soc2,
      gdpr,

      mobile: {
        sydLeftFaceBackgroundMobile,
        sydLeftFaceMobile,
      },
    },

    button: {
      tick: buttonTick,
      tickBlack: buttonTickBlack,
    },

    inputs: {
      passwordOn,
      passwordOff,
    },

    onboarding: {
      gridItemCheck,
      gridSelectionRemove,
      lqiDirectionUp,
      lqiHeader,
      lqiHeaderVideoMobile,
      lqiVideo,
      lqiVideoDesktop,
      completeLogo,

      tooltipTriangle,

      securityLogos,
      ratingLogos,
      checkInBottomGradient,
      finishedLogo,

      new: {
        tick,
        check,
        syd,
        google,
        apple,
        tapToDownloadLogo,
      },
    },

    password: {
      passwordTick,
    },
  },
};
