import {
  Autocomplete,
  Box,
  Fade,
  Grid,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGetCountriesQuery } from '../../api/api';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Country } from '../../data/countries';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { ContinueRef } from '../../onboarding/types';
import { useShoppingCart } from '../../onboarding/common/hooks';
import { checkoutActions } from '../../redux/checkout';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import i18next from 'i18next';
import { appEnvironment, autoCompleteData } from '../../common/environment';
import { useMobile } from '../../common/hooks';

const MIN_DATE = moment().subtract(14, 'years').startOf('month');

const SecondForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const { data: countries } = useGetCountriesQuery(false);

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      dispatch(
        checkoutActions.setPersonalData({
          gender: selectedGender,
          birthDate: `01-${
            selectedMonth < 10 ? '0' : ''
          }${selectedMonth}-${selectedYear}`,
        })
      );
      dispatch(checkoutActions.setCountry(selectedCountry!));

      dispatch(onboardingActions.setOnboardingStep(nextStep));
    },
  }));

  const dispatch = useAppDispatch();

  const { isPremium } = useShoppingCart();

  const nextStep = useMemo(() => {
    if (isPremium) {
      return OnboardingV2Step.DELIVERY_ADDRESS;
    } else {
      return OnboardingV2Step.SUMMARY;
    }
  }, [isPremium]);

  const autocompleteRef = useRef<HTMLDivElement>(null);

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(
    appEnvironment.autocomplete
      ? autoCompleteData.birthDate.get('year') + 1
      : -1
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    appEnvironment.autocomplete
      ? autoCompleteData.birthDate.get('month') + 1
      : -1
  );
  const [selectedGender, setSelectedGender] = useState<
    'M' | 'F' | 'NB' | 'none'
  >(appEnvironment.autocomplete ? 'M' : 'none');

  const years = useMemo(() => {
    const start = MIN_DATE.clone();

    const result = [MIN_DATE.get('year')];
    for (let i = 0; i < 100; i++) {
      start.subtract(1, 'year');
      result.push(start.get('year'));
    }

    return result;
  }, []);

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => i + 1);
  }, []);

  const hasInitialisedCountry = useRef(false);

  const { t } = useTranslation();

  const mappedGender = useMemo(() => {
    switch (selectedGender) {
      case 'M':
        return t('maleUppercase');
      case 'F':
        return t('femaleUppercase');
      case 'NB':
        return t('nonBinaryUppercase');
      default:
        return 'none';
    }
  }, [selectedGender, t]);

  useEffect(() => {
    dispatch(
      onboardingActions.setButtonEnabled(
        selectedYear > 0 &&
          selectedMonth > 0 &&
          selectedGender !== 'none' &&
          selectedCountry !== null
      )
    );
  }, [selectedCountry, selectedYear, selectedMonth, dispatch, selectedGender]);

  useEffect(() => {
    if (countries) {
      if (hasInitialisedCountry.current) {
        return;
      }
      hasInitialisedCountry.current = true;

      let index = 0;
      if (i18next.resolvedLanguage === 'ja') {
        index = countries.findIndex((c) => c.code === 'JP');
      } else {
        index = countries.findIndex((c) => c.code === 'GB');
      }

      setSelectedCountry(countries[index >= 0 ? index : 0]);
    }
  }, [countries, selectedCountry]);

  const mobile = useMobile();

  if (!countries || !selectedCountry) {
    return null;
  }

  return (
    <Fade in={true}>
      <Grid
        container
        className="form-root"
        rowSpacing={'10px'}
        alignContent={mobile ? 'flex-start' : undefined}
        justifyContent={'flex-start'}
        sx={mobile ? { flex: 1, padding: '120px 16px 0px' } : {}}
      >
        <Grid
          item
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          container={true}
        >
          <Typography className="form-label">
            {t('birthdateUpperColon')}
          </Typography>
        </Grid>

        <Grid item xs={12} container columnSpacing={'10px'}>
          <Grid item xs={6}>
            <Select
              variant="outlined"
              className="autocomplete"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value as number)}
              renderValue={(value) => {
                if (value < 0) {
                  return (
                    <Typography className="form-label">{t('month')}</Typography>
                  );
                }

                return `${value < 10 ? '0' : ''}${value}`;
              }}
            >
              <MenuItem value={-1} sx={{ display: 'none' }}>
                {t('month')}
              </MenuItem>

              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month < 10 ? '0' : ''}
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Select
              variant="outlined"
              className="autocomplete"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value as number)}
              renderValue={(value) => {
                if (value < 0) {
                  return (
                    <Typography className="form-label">{t('year')}</Typography>
                  );
                }

                return value;
              }}
            >
              <MenuItem value={-1} sx={{ display: 'none' }}>
                {t('year')}
              </MenuItem>

              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          container={true}
        >
          <Typography className="form-label">
            {t('genderUpperColor')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Select
            placeholder="Select country"
            variant="outlined"
            className="autocomplete"
            value={selectedGender}
            onChange={(e) =>
              setSelectedGender(e.target.value as typeof selectedGender)
            }
            renderValue={(value) => {
              if (value === 'none') {
                return (
                  <Typography className="form-label">
                    {t('selectGender')}
                  </Typography>
                );
              }

              return mappedGender;
            }}
          >
            <MenuItem value={'none'} sx={{ display: 'none' }}>
              {t('selectGender')}
            </MenuItem>

            <MenuItem value={'M'}>{t('maleUppercase')}</MenuItem>
            <MenuItem value={'F'}>{t('femaleUppercase')}</MenuItem>
            <MenuItem value={'NB'}>{t('nonBinaryUppercase')}</MenuItem>
          </Select>
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          container={true}
        >
          <Typography className="form-label">
            {t('countryUpperColon')}
          </Typography>
        </Grid>

        <Grid item xs={12} justifyContent={'center'}>
          <Autocomplete
            options={countries}
            className="autocomplete"
            onChange={(_, value) => {
              setSelectedCountry(value);
            }}
            disableClearable={true}
            value={selectedCountry}
            PopperComponent={(props) => {
              return (
                <Popper
                  {...props}
                  anchorEl={autocompleteRef.current}
                  style={{
                    width: autocompleteRef.current?.clientWidth,
                  }}
                />
              );
            }}
            renderInput={(props) => {
              return (
                <Stack
                  className="bg-white rounded-text-input w-full px-4 hover:bg-white filled-input"
                  direction={'row'}
                  gap={'10px'}
                  alignItems={'center'}
                  ref={autocompleteRef}
                >
                  <Box
                    component={'img'}
                    src={selectedCountry.flag}
                    className="rounded-[36px] w-[36px] h-[36px]"
                  />
                  <TextField
                    {...props}
                    variant="outlined"
                    InputProps={{
                      ...props.InputProps,
                    }}
                    value={selectedCountry.label}
                  />
                </Stack>
              );
            }}
            renderOption={(props, option) => {
              return (
                <Box key={option.code} component={'li'} {...props}>
                  <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                    <Box
                      component={'img'}
                      src={option.flag}
                      className="rounded-[36px] w-[36px] h-[36px]"
                    />
                    <Typography>{option.label}</Typography>
                  </Stack>
                </Box>
              );
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          container={true}
        >
          <Typography
            className="form-label legend"
            sx={{ textAlign: 'center' }}
          >
            {t('withThisInformation')}
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
});

export default SecondForm;
