import {
  Box,
  GlobalStyles,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { theme } from './common/theme';
import './logged-out.css';
import { images } from './assets/images';
import { LoadingButton } from '@mui/lab';
import SignUpForm from './components_v2/forms/SignUpForm';
import { useAppDispatch, useAppSelector } from './redux/store';
import { useEffect, useMemo, useRef } from 'react';
import { ContinueRef } from './onboarding/types';
import { OnboardingV2Step } from './redux/onboardingTypes';
import DateOfBirth from './components_v2/forms/DateOfBirth';
import SelectCountryForm from './components_v2/forms/SelectCountryForm';
import CountryForm from './components_v2/forms/CountryForm';
import GenderForm from './components_v2/forms/GenderForm';
import SummaryV2Form from './components_v2/forms/SummaryV2Form';
import { onboardingActions } from './redux/onboarding';
import AddressV2 from './components_v2/forms/AddressvV2';
import SYDLeftPanelContainer from './components_v2/SYDLeftPanelContainer';
import StripeForm from './components_v2/forms/StripeForm';
import { useSearchParams } from 'react-router-dom';
import CheckoutForm from './components_v2/forms/CheckoutForm';
import { useTranslation } from 'react-i18next';
import { useAppTransitions } from './ui';
import HeaderProgressBar from './components_v2/header/HeaderPogressBar';
import { useLoggedOutHeader } from './headerHooks';
import SecondForm from './components_v2/forms/SecondForm';

export default function LoggedOutV2() {
  const { t } = useTranslation();

  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );
  const onboardingStep = useAppSelector(
    (state) => state.onboarding.onboardingStep
  );

  const loading = useAppSelector((state) => state.checkout.loading);
  const showingMessages = useAppSelector(
    (state) => state.onboarding.showingMessages
  );

  const leftPanelImageRef = useRef<HTMLImageElement>(null);
  const { leftPanelTranslationX, animatedRightPanelInOnboarding } =
    useAppTransitions(leftPanelImageRef, true);

  const { step, show, subtext } = useLoggedOutHeader();

  const ref = useRef<ContinueRef>(null);
  const rightView = useMemo(() => {
    switch (onboardingStep) {
      case OnboardingV2Step.DATE_OF_BIRTH:
        return <DateOfBirth ref={ref} />;
      case OnboardingV2Step.SECOND_FORM:
        return <SecondForm ref={ref} />;
      case OnboardingV2Step.COUNTRY:
        return <CountryForm ref={ref} />;
      case OnboardingV2Step.COUNTRY_SELECT:
        return <SelectCountryForm />;
      case OnboardingV2Step.GENDER:
        return <GenderForm ref={ref} />;
      case OnboardingV2Step.BILLING_ADDRESS:
        return <AddressV2 ref={ref} type="billing" />;
      case OnboardingV2Step.DELIVERY_ADDRESS:
        return <AddressV2 ref={ref} type="delivery" />;
      case OnboardingV2Step.PAYMENT_INFO:
        return <StripeForm ref={ref} />;
      case OnboardingV2Step.SUMMARY:
        return <SummaryV2Form ref={ref} />;
      case OnboardingV2Step.SIGN_UP:
      default:
        return <SignUpForm ref={ref} />;
    }
  }, [onboardingStep]);

  const hasRun = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasRun.current && onboardingStep === OnboardingV2Step.WELCOME) {
      hasRun.current = true;

      animatedRightPanelInOnboarding();

      dispatch(onboardingActions.setButtonEnabled(true));
      dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.INTRO));
    }
  }, [animatedRightPanelInOnboarding, dispatch, onboardingStep]);

  const stepTitle = useMemo(() => {
    switch (onboardingStep) {
      case OnboardingV2Step.SIGN_UP:
        return t('completeTheFieldsBelow');
      case OnboardingV2Step.SECOND_FORM:
        return t('yourDateOfBirth');
      default:
        return undefined;
    }
  }, [onboardingStep, t]);

  const [searchParams] = useSearchParams();

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className="root-container">
          <Box
            component="img"
            src={images.header.sydLogo}
            className="logo w-[76px] h-[76px] m-10"
          />

          {show && (
            <HeaderProgressBar
              label={t('signUp')}
              step={step}
              totalSteps={2}
              subtext={subtext}
              blue={onboardingStep === OnboardingV2Step.SUMMARY}
              stepTitle={stepTitle}
            />
          )}

          <Stack direction="row" className="w-full flex-1 h-0 relative">
            <Stack direction="row" className="w-full" alignSelf="center">
              {searchParams.get('payment_intent') !== null ? (
                <CheckoutForm />
              ) : (
                <>
                  <Stack
                    direction="row"
                    flexGrow={1}
                    className={`w-0 ${
                      ![
                        OnboardingV2Step.ANIMATING,
                        OnboardingV2Step.INTRO,
                        OnboardingV2Step.WELCOME,
                      ].includes(onboardingStep)
                        ? ''
                        : 'transition-transform duration-1000'
                    }`}
                    alignSelf="center"
                    justifyContent="center"
                    sx={{ transform: `translateX(${leftPanelTranslationX}px)` }}
                  >
                    <SYDLeftPanelContainer imageRef={leftPanelImageRef} />
                  </Stack>

                  {![
                    OnboardingV2Step.ANIMATING,
                    OnboardingV2Step.INTRO,
                    OnboardingV2Step.WELCOME,
                  ].includes(onboardingStep) && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      className="absolute left-0 top-0 right-0 bottom-0"
                    >
                      <Stack
                        direction="row"
                        flexGrow={1}
                        justifyContent="center"
                        alignItems="center"
                        alignSelf="center"
                        className="w-0 macbook13:mt-[0px] mt-[80px] max-w-[700px]"
                      >
                        {rightView}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Stack>

          {onboardingStep === OnboardingV2Step.ANIMATING ? (
            <Typography className="font-medium text-[14px] text-[#26262680] my-10 self-center">
              {t('establishingPrivateAndSecureConnection')}
            </Typography>
          ) : (
            <Stack
              className="macbook13:my-0 macbook13:mb-[20px] my-10"
              alignItems={'center'}
            >
              <Typography component={'span'} className="signUp-note">
                <Typography component={'span'} className="signUp-note dark">
                  98%
                </Typography>{' '}
                {t('percentOfMembers')}
              </Typography>

              <LoadingButton
                variant="contained"
                endIcon={
                  loading || showingMessages ? undefined : (
                    <Box component="img" src={images.v2.button.tick} />
                  )
                }
                className={`button large bg-text ${
                  onboardingStep !== OnboardingV2Step.WELCOME
                    ? 'opacity-100'
                    : 'opacity-0'
                }`}
                onClick={() => {
                  if (onboardingStep !== OnboardingV2Step.PAYMENT_INFO) {
                    dispatch(onboardingActions.setButtonEnabled(false));
                  }

                  if (ref.current) {
                    ref.current.continuePressed();
                  }
                }}
                loading={loading || showingMessages}
                disabled={!buttonEnabled || showingMessages}
              >
                {onboardingStep === OnboardingV2Step.SUMMARY
                  ? t('downloadTheApp')
                  : t('continue')}
              </LoadingButton>
            </Stack>
          )}

          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className="absolute left-[40px] right-[40px] bottom-[20px] pointer-events-none z-[-1]"
          >
            <Box
              component={'img'}
              src={images.v2.onboarding.securityLogos}
              className="h-[40px]"
            />
            <Box
              component={'img'}
              className="h-[40px]"
              src={images.v2.onboarding.ratingLogos}
            />
          </Stack>
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
