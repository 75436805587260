import {
  GlobalStyles,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { theme } from '../common/theme';
import QRForm from '../components_v2/logged_in/QRForm';

export default function OnboardingSignUpView() {
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className="root-container">
          <Stack className={`flex-1 self-stretch bg-[#F6F5F5]`}>
            <Stack
              className="p-[24px] mt-[20px] h-0 grow z-50 overflow-y-scroll"
              justifyContent="flex-start"
              alignItems={'flex-start'}
              gap="8px"
            >
              <QRForm />
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
