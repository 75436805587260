import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../common/hooks';

export default function HeaderProgressBar(props: Props) {
  const { label, step, totalSteps, subtext, blue, stepTitle } = props;

  const { t } = useTranslation();

  const firstWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100;
    const width = (progress / 50) * 100;

    return width > 100 ? 100 : width;
  }, [step, totalSteps]);

  const secondWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100 - 50;
    const width = (progress / 50) * 100;
    return width < 0 ? 0 : width;
  }, [step, totalSteps]);

  const mobile = useMobile();

  return (
    <Stack
      className={`m-[2.5em] absolute left-0 right-0 items-center justify-center ${
        mobile ? 'left-[80px] right-[80px]' : ''
      }`}
    >
      <Stack className="items-center">
        <Typography
          className={`text-[#262626] text-[24px] font-semibold ${
            mobile ? 'mb-[10px]' : 'mb-[20px]'
          }`}
        >
          {label}
        </Typography>

        <Stack className="flex-row gap-x-[10px]">
          <Box className="w-[172px] h-[6px] rounded-[36px] bg-white relative">
            <Box
              className={`absolute left-0 top-0 bottom-0 rounded-[36px] ${
                blue ? 'bg-mint' : 'bg-text'
              }`}
              sx={{ width: `${firstWidth}%` }}
            />
          </Box>

          <Box className="w-[172px] h-[6px] rounded-[36px] bg-white relative">
            <Box
              className={`absolute left-0 top-0 bottom-0 rounded-[36px] ${
                blue ? 'bg-mint' : 'bg-text'
              }`}
              sx={{ width: `${secondWidth}%` }}
            />
          </Box>
        </Stack>

        <Typography className="mt-[8px] text-[14px] font-medium text-[#26262680]">
          {subtext
            ? subtext
            : t('headerStep', {
                step,
                totalSteps,
              })}
        </Typography>

        {stepTitle && (
          <Typography className="header-stepTitle">{stepTitle}</Typography>
        )}
      </Stack>
    </Stack>
  );
}

export interface Props {
  label: string;
  step: number;
  totalSteps: number;

  subtext?: string;
  blue?: boolean;
  stepTitle?: string;
}
