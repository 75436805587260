import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContinueRef } from '../../onboarding/types';
import { Fade, Stack } from '@mui/material';
import { images } from '../../assets/images';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { useMobile } from '../../common/hooks';
import { checkoutActions } from '../../redux/checkout';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import GenderCellV2 from '../chat/GenderCellV2';
import { useSYDMessages } from '../../common/v2/hooks';
import { useShoppingCart } from '../../onboarding/common/hooks';
import LoadingMessagesContainer from './LoadingMessagesContainer';
import { useTranslation } from 'react-i18next';

const GenderForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
        screen: analyticsScreenNames.GENDER,
      });

      dispatch(checkoutActions.setPersonalData({ gender: mappedGender }));
      if (nextStep === OnboardingV2Step.DELIVERY_ADDRESS && mobile) {
        showMessages([gender, t('whereShouldIShip')], 0, true).then(() => {
          dispatch(onboardingActions.setOnboardingStep(nextStep));
        });
      } else {
        dispatch(onboardingActions.setOnboardingStep(nextStep));
      }
    },
  }));

  const dispatch = useAppDispatch();
  const [gender, setGender] = useState<'male' | 'female' | 'nonbinary'>('male');

  const country = useAppSelector((state) => state.checkout.country);

  const { isPremium } = useShoppingCart();

  const mobile = useMobile();

  const nextStep = useMemo(() => {
    if (isPremium) {
      return OnboardingV2Step.DELIVERY_ADDRESS;
    } else {
      return OnboardingV2Step.SUMMARY;
    }
  }, [isPremium]);

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_GENDER_SCREEN);
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  const mappedGender = useMemo(() => {
    switch (gender) {
      case 'male':
        return 'M';
      case 'female':
        return 'F';
      case 'nonbinary':
        return 'NB';
    }
  }, [gender]);

  const { showMessages } = useSYDMessages();
  const hasShownMessagesRef = useRef(false);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (!hasShownMessagesRef.current) {
      hasShownMessagesRef.current = true;
      showMessages([country.label, t('whatsYourGender')], 0, true).then(() =>
        setFadeIn(true)
      );
    }
  }, [country.label, showMessages, t]);

  return (
    <LoadingMessagesContainer>
      <Fade in={fadeIn}>
        <Stack
          direction="row"
          justifyContent={mobile ? 'space-between' : 'center'}
          alignItems={mobile ? 'flex-start' : 'center'}
          gap={mobile ? '0px' : '80px'}
          className={mobile ? 'm-[24px] mb-[0px]' : ''}
        >
          <GenderCellV2
            selected={gender === 'male'}
            imageSrc={images.genders.male}
            gender={t('male')}
            onSelected={() => setGender('male')}
            sx={mobile ? { marginTop: '60px' } : {}}
          />

          {mobile ? (
            <>
              <GenderCellV2
                selected={gender === 'nonbinary'}
                imageSrc={images.genders.nonBinary}
                gender={t('nonBinary')}
                onSelected={() => setGender('nonbinary')}
              />

              <GenderCellV2
                selected={gender === 'female'}
                imageSrc={images.genders.female}
                gender={t('female')}
                onSelected={() => setGender('female')}
                sx={mobile ? { marginTop: '120px' } : {}}
              />
            </>
          ) : (
            <Stack
              justifyContent={mobile ? 'space-between' : 'center'}
              columnGap={mobile ? '0px' : '30px'}
              flexWrap="wrap"
              gap={mobile ? '0px' : '80px'}
              direction={mobile ? 'row' : 'column'}
            >
              <GenderCellV2
                selected={gender === 'nonbinary'}
                imageSrc={images.genders.nonBinary}
                gender={t('nonBinary')}
                onSelected={() => setGender('nonbinary')}
              />

              <GenderCellV2
                selected={gender === 'female'}
                imageSrc={images.genders.female}
                gender={t('female')}
                onSelected={() => setGender('female')}
              />
            </Stack>
          )}
        </Stack>
      </Fade>
    </LoadingMessagesContainer>
  );
});

export default GenderForm;
