import { useMemo } from 'react';
import { OnboardingV2Step } from './redux/onboardingTypes';
import { useAppSelector } from './redux/store';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const useLoggedOutHeader = () => {
  const { t } = useTranslation();

  const onboardingStep = useAppSelector(
    (state) => state.onboarding.onboardingStep
  );

  const [searchParams] = useSearchParams();

  const white = useMemo(() => {
    switch (onboardingStep) {
      case OnboardingV2Step.SIGN_UP:
      case OnboardingV2Step.MOBILE_SIGN_UP:
      case OnboardingV2Step.BILLING_ADDRESS:
      case OnboardingV2Step.DELIVERY_ADDRESS:
      case OnboardingV2Step.PAYMENT_INFO:
        return true;
      default:
        return false;
    }
  }, [onboardingStep]);

  const show = useMemo(() => {
    if (searchParams.get('payment_intent') !== null) {
      return false;
    }

    switch (onboardingStep) {
      case OnboardingV2Step.ANIMATING:
      case OnboardingV2Step.MOBILE_NONE:
      case OnboardingV2Step.MOBILE_ANIMATING:
      case OnboardingV2Step.MOBILE_BUTTON:
      case OnboardingV2Step.WELCOME:
      case OnboardingV2Step.INTRO:
        return false;
      default:
        return true;
    }
  }, [onboardingStep, searchParams]);

  const step = useMemo(() => {
    switch (onboardingStep) {
      case OnboardingV2Step.SIGN_UP:
      case OnboardingV2Step.MOBILE_SIGN_UP:
        return 1;
      case OnboardingV2Step.SECOND_FORM:
      case OnboardingV2Step.DELIVERY_ADDRESS:
      case OnboardingV2Step.SUMMARY:
        return 2;
      default:
        return 1;
    }
  }, [onboardingStep]);

  const subtext = useMemo(() => {
    switch (onboardingStep) {
      case OnboardingV2Step.BILLING_ADDRESS:
      case OnboardingV2Step.DELIVERY_ADDRESS:
        return t('deliveryInformation');
      case OnboardingV2Step.PAYMENT_INFO:
        return t('billingInformation');
    }
  }, [onboardingStep, t]);

  return { show, step, subtext, white };
};
