export interface OnboardingAnswer {
  id: string;
  imgUrl: string;
  imgUrl_bkg: string;
  imgUrl_v2: string;

  text: string;
  description: string;
  font_color: string;
}

export interface OnboardingQuestion {
  answers: OnboardingAnswer[];
  multiChoice: boolean;
  progress: number;
  question_id: string;
  question_text: string;
  question_subtext: string;
  question_timestamp: string;

  is_goal: boolean;
  is_height: boolean;
  is_weight: boolean;
  lqi_scale: boolean;
  is_categorical: boolean;
  is_lqi_dce: boolean;
  is_info: boolean;
  is_imperial: boolean;

  is_interests: boolean;
  is_diets: boolean;
  is_allegens: boolean;
  is_lqi_calc: boolean;

  finished: boolean;

  question_idx: number;
  questions_total: number;

  text_info: string;
  answer: string;

  messages: string[];
  insight: string;
  status?: { text: string; value?: number };
}

export interface OnboardingQuestionInfo {
  subtext?: string;
  text?: string;
  title?: string;
}

export interface OnboardingResponse {
  question_timestamp: string;
  question_id: string;
  answers: string[];
  imperial?: boolean;
}

export interface LQIScaleResponse {
  lqis: { id_lqi: string; value: number }[];
  question_timestamp: string;
}

export interface OnboardingContinueRef {
  onContinuePressed: () => Promise<unknown>;
}

export interface SYDMesssage {
  text: string;
  from: 'syd' | 'user';
  type: 'normal';
  index: number;
}

export enum OnboardingV2Step {
  ANIMATING = 'ANIMATING',
  WELCOME = 'WELCOME',
  INTRO = 'INTRO',
  SIGN_UP = 'SIGN_UP',
  SECOND_FORM = 'SECOND_FORM',

  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  COUNTRY = 'COUNTRY',
  COUNTRY_SELECT = 'COUNTRY_SELECT',
  GENDER = 'GENDER',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  PAYMENT_INFO = 'PAYMENT_INFO',
  SUMMARY = 'SUMMARY',

  LOGGED_IN = 'LOGGED_IN',

  MOBILE_SIGN_UP = 'MOBILE_SIGN_UP',
  MOBILE_ANIMATING = 'MOBILE_ANIMATING',
  MOBILE_BUTTON = 'MOBILE_BUTTON',
  MOBILE_NONE = 'MOBILE_NONE',
}
