import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, GlobalStyles, Stack, Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { images } from './assets/images';
import { theme } from './common/theme';

import './logged-in.css';
import QRForm from './components_v2/logged_in/QRForm';
import SYDLeftPanelContainer from './components_v2/SYDLeftPanelContainer';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppTransitions } from './ui';
import HeaderProgressBar from './components_v2/header/HeaderPogressBar';

export default function LoggedInV2() {
  const { t } = useTranslation();

  const leftPanelImageRef = useRef<HTMLImageElement>(null);

  const { leftPanelTranslationX } = useAppTransitions(leftPanelImageRef, false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      searchParams.get('lng') !== null ? `lng=${searchParams.get('lng')}` : ''
    );
  }, [searchParams, setSearchParams]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className={`root-container`}>
          <HeaderProgressBar
            label={t('signUp')}
            step={2}
            totalSteps={2}
            subtext={t('completedExclamationMark')}
            blue={true}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component="img"
              src={images.header.sydLogo}
              className="logo w-76 h-76 m-10"
            />
          </Stack>

          <Stack
            direction="row"
            className={`w-full flex-1 h-0 relative transition-opacity ${
              leftPanelTranslationX !== 0 ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <Stack direction="row" className="w-full" alignSelf="center">
              <Stack
                direction="row"
                flexGrow={1}
                className="w-0"
                alignSelf="center"
                justifyContent="center"
                sx={{ transform: `translateX(${leftPanelTranslationX}px)` }}
              >
                <SYDLeftPanelContainer imageRef={leftPanelImageRef} />
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                className="absolute left-0 top-0 right-0 bottom-0"
              >
                <Stack
                  direction="row"
                  flexGrow={1}
                  justifyContent="center"
                  alignItems="center"
                  alignSelf="center"
                  className="w-0 mt-[80px]"
                >
                  <QRForm />
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Box className="h-[48px] my-10" />
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
