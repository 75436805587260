import { Box, Fade } from '@mui/material';
import { images } from '../assets/images';
import SYDLeftPanel from './SYDLeftPanel';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { OnboardingV2Step } from '../redux/onboardingTypes';
import { wait } from '../common/v2/hooks';
import { onboardingActions } from '../redux/onboarding';
import { appEnvironment } from '../common/environment';

const TIMEOUT = 700;

export default function SYDLeftPanelContainer(props: Props) {
  const { imageRef } = props;

  const [animatingIndex, setAnimatingIndex] = useState(0);

  const onboardingStep = useAppSelector(
    (state) => state.onboarding.onboardingStep
  );
  const dispatch = useAppDispatch();
  const hasAnimated = useRef(false);

  useEffect(() => {
    const animate = async () => {
      let index = 0;
      while (onboardingStep === OnboardingV2Step.ANIMATING) {
        index = (index + 1) % 5;

        setAnimatingIndex(index);
        await wait(TIMEOUT);
      }
    };

    if (!hasAnimated.current && onboardingStep === OnboardingV2Step.ANIMATING) {
      hasAnimated.current = true;

      animate();
      setTimeout(() => {
        dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.WELCOME));
      }, appEnvironment.splashTimeout);
    }
  }, [dispatch, onboardingStep]);

  return (
    <>
      <Box className="relative">
        <Box component="img" src={images.v2.leftPanel.sydLeftPanelBackground} />

        {onboardingStep === OnboardingV2Step.ANIMATING && (
          <>
            <Fade in={animatingIndex === 0} timeout={TIMEOUT}>
              <Box
                component="img"
                src={images.v2.leftPanel.iso}
                className="absolute left-[130px] bottom-[70px]"
              />
            </Fade>

            <Fade in={animatingIndex === 1} timeout={TIMEOUT}>
              <Box
                component="img"
                src={images.v2.leftPanel.soc2}
                className="absolute left-[130px] bottom-[70px]"
              />
            </Fade>

            <Fade in={animatingIndex === 2} timeout={TIMEOUT}>
              <Box
                component="img"
                src={images.v2.leftPanel.hipaa}
                className="absolute left-[130px] bottom-[70px]"
              />
            </Fade>

            <Fade in={animatingIndex === 3} timeout={TIMEOUT}>
              <Box
                component="img"
                src={images.v2.leftPanel.orcha}
                className="absolute left-[130px] bottom-[70px]"
              />
            </Fade>

            <Fade in={animatingIndex === 4} timeout={TIMEOUT}>
              <Box
                component="img"
                src={images.v2.leftPanel.gdpr}
                className="absolute left-[130px] bottom-[70px]"
              />
            </Fade>
          </>
        )}

        <Fade
          in={onboardingStep !== OnboardingV2Step.ANIMATING}
          timeout={TIMEOUT}
        >
          <Box
            ref={imageRef}
            component="img"
            src={images.v2.leftPanel.sydLeftPanelFaceDesktop}
            className="absolute left-[130px] bottom-[70px] w-[220px] h-[220px]"
          />
        </Fade>
      </Box>

      <SYDLeftPanel />
    </>
  );
}

interface Props {
  imageRef: RefObject<HTMLImageElement>;
}
